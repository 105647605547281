<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Cities List
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Cities</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-3">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="number" class="form-control" @change="paginateChange" placeholder="perPage" v-model="paginate">
          </div>
        </form>
      </div>
      <div class="col-9">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="text" class="form-control" placeholder="Search Here" v-model="searchInput">
            <button type="button" @click="searchCities" class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0" :disabled="searchInput == ''">Search</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Users</h4>
              <download-excel
                class="btn btn-success"
                :data="cities"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="cities.xlsx">
                Download Users
              </download-excel>
            </div>
<!--            <h4 class="card-title">Cities</h4>-->
            <b-table :items="cities" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

              <template v-slot:cell(action)="data">
                <span><button v-b-modal.editModal @click="editCity(data.item)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>Edit</button></span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
    <!-- edit modal -->
    <b-modal id="editModal" :hide-footer=true :title="'Edit ' + city_name" size="md">
      <ValidationObserver v-slot="{handleSubmit, invalid}" ref="form">
        <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
         <div class="row">
           <div class="col-md-12 grid-margin stretch-card">
             <div class="card">
               <div class="card-body">
                 <ValidationProvider name="style_id" rules="required">
                   <b-form-group slot-scope="{ errors }" label-for="style_id" horizontal label="Style">
                     <b-form-select id="style_id" v-model="style_id" :options="styles"/>
                    <p>{{ errors[0] }}</p>
                   </b-form-group>
                 </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <b-form-group class="float-right">
                    <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
                 </b-form-group>
               </div>
             </div>
           </div>
         </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import { ValidationObserver } from 'vee-validate'

import helper from '../../util/helper.js'
import _ from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      city_name: "",
      style_url: "",
      style_id: "",
      city_id: "",
      create_text: "Update City",
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      isBusy: true,
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      sortable: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true},
        { key: 'created_at', sortable: true},
        { key: 'action', sortable: false}
      ],
      json_fields: {
        'ID': 'id',
        "Name": "name",
        Plans: 'plans_count',
      }
    };
  },
  components: {
    ValidationObserver
  },
  computed: {
    rows() {
      return this.$store.state.city.meta.total
    },
    cities() {
      return this.$store.state.city.cities
    },
    styles() {
      return this.$store.getters["plan/listStyles"]
    }

  },
  watch: {
    currentPage() {
      if (this.currentPage != this.$store.state.transaction.meta.current_page)
        this.fetchCities(this.currentPage)
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    editCity(id) {
      this.fetchStyles(id)
    },
    onSubmit() {
      this.create_text = "Updating..."

      let payload = {
        id: this.city_id,
        style_id: this.style_id
      }

      this.$store.dispatch("city/updateCity", payload)
      .then(() => {
        this.$snotify.success("City updated successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
        this.create_text = "Update City"
      })
      .catch(() => {
        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
        this.create_text = "Update City"
      })
    },
    searchCities() {
      if(this.searchInput == "")
        return

      this.isBusy = true
      let payload = {
        meta: {
          term: this.searchInput,
        }
      }

      this.$store.dispatch("city/searchCities", payload).then(() => {
        this.isBusy = false
      })
    },
    fetchCities(current_page = 1) {
      this.isBusy = true
      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate
        },
      }
      this.$store.dispatch("city/fetchCities", payload).then(() => {
        this.isBusy = false
      })
    },
    fetchStyles(city) {
      this.city_name = city.name
      this.city_id = city.id
      this.mapbox_style_url = city.mapbox_style_url
      const payload = {
        meta: {
          city_id: city.id
        }
      }
      this.$store.dispatch("plan/fetchStyles", payload)
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchCities()
    }, 500),
  },
  created() {
    this.fetchCities()
 },
}
</script>
